import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Merchant from "./main-pages/Merchant";
import Dashboard from "./main-pages/Dashboard";
import Owner from "./main-pages/Owner";
import "./Dashboard.css";
import AdminRegister from "./main-pages/AdminRegister";
import AdminLogin from "./main-pages/AdminLogin";
import MainAdminDashboard from "./main-pages/MainAdminDashboard";
// import MerchantSubscription from "./main-pages/MerchantSubscription";
import MerchantSubscription from "./main-pages/merchantSubscription";
import SubscriptionDetails from "./main-pages/SubscriptionDetails";

import PrivateRoute, { PrivateRouteFinancial } from "./utils/PrivateRoute";
import AuthContext, { AuthProvider } from "./context/AuthContext";
import AdminMerchantData from "./components/AdminMerchantData";
import AdminOwnerData from "./components/AdminOwnerData";
import Charges from "./main-pages/Charges";
import Payout from "./main-pages/Payout";
import NavBar from "./components/NewNavBar";
import Sidebar from "./components/Sidebar";
import { Container, Row, Col, Button } from "react-bootstrap";
import SendSMSMessages from "./main-pages/SendSMSMessages";
import MessagesList from "./main-pages/MessagesList";
import { ToastContainer } from "react-toastify";
import { getCookie } from "typescript-cookie";
import Email from "./main-pages/Email";
import { BsList } from "react-icons/bs";
import ProfileData from "./main-pages/ProfileData";
import ImageUpload from "./ImageUploadToFirebase";
import Products from "./main-pages/Products";
import CreateProduct from "./components/products/CreateProduct";
import { PermissionsProvider } from "./permissions/PermissionsContext";
import ProtectedRoute from "./permissions/ProtectedRoute";
import ManagePermissions from "./permissions/ManagePermissions";
import Unauthorized from "./permissions/Unauthorized";
import { ImageProvider } from "./ImageContext";
import ExcelUploader from "./main-pages/ExcelUploader";
import ExcelViewer from "./main-pages/ExcelViewer";
import Maintenance from "./main-pages/Maintenance";
function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  let [authTokens, setAuthTokens] = useState(() =>
    getCookie("jwt_authtoken") ? getCookie("jwt_authtoken") : null
  );

  const toggleSidebar = () => {
    setIsSidebarOpen((current) => !current);
  };

  return (
    <>
      <ToastContainer hideProgressBar={true} newestOnTop={true} />
      <div
        style={{
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Router>
        <ImageProvider>

          <AuthProvider>
            <PermissionsProvider>
              
              <div
                style={{
                  height: "0vh",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgb(250,251,255)",
                }}
              >
                <Routes>
                  <Route path="/admin-login" element={<AdminLogin />}></Route>
                </Routes>
              </div>
              <div
                style={{
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgb(250,251,255)",
                }}
              >
                <Container fluid="100%">
                  <Row>
                    <NavBar />
                  </Row>
                  <Row>
                    {authTokens && (
                      <>
                        <div
                          className="toggle-sidebar-div"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            zIndex: "1",
                            marginLeft: "2rem",
                            marginTop: "-3rem",
                            position: "fixed",
                            width: "3.5rem",
                          }}
                        >
                          <button
                            title={
                              isSidebarOpen ? "Close Sidebar" : "Open Sidebar"
                            }
                            onClick={toggleSidebar}
                            id="arrowBtn"
                          >
                            <p
                              style={{
                                fontSize: "20px",
                                color: "rgb(62,0,194)",
                              }}
                            >
                              <BsList />
                            </p>
                          </button>
                        </div>
                        <Col
                          id="fistColumn"
                          className={isSidebarOpen ? "open" : ""}
                        >
                          <Sidebar
                            // onSidebarItemClick={handleSidebarItemClick}
                            toggleSidebar={toggleSidebar}
                            setIsSidebarOpen={setIsSidebarOpen}
                          />
                        </Col>
                      </>
                    )}

                    <Col
                      id="secondColumn"
                      className={isSidebarOpen ? "open" : ""}
                    >
                      <Routes>
                      <Route
                      path="/imageUploadToFirebase"
                      element={<ImageUpload />}
                    ></Route>

                        <Route
                          path="/profile"
                          element={<ProfileData />}
                          exact
                        />
                        {/* <Route path="/admin-login" element={<AdminLogin />}></Route> */}

                        <Route element={<PrivateRouteFinancial />}>
                          <Route
                            path="/sms"
                            element={<SendSMSMessages />}
                            exact
                          ></Route>
                          <Route
                            path="/messages"
                            element={<MessagesList />}
                            exact
                          ></Route>
                        </Route>

                        <Route element={<PrivateRoute />}>
                          <Route
                            path="/"
                            element={
                              <ProtectedRoute requiredPermission="dashboard">
                                <Dashboard />
                              </ProtectedRoute>
                            }
                            exact
                          />
                          
                          <Route
                            path="/manage-permissions"
                            element={
                              <ProtectedRoute requiredPermission="manage_users">
                                <ManagePermissions />
                              </ProtectedRoute>
                            }
                            exact
                          />
                          <Route
                            path="/unauthorized"
                            element={
                                <Unauthorized />
                            }
                            exact
                          />

                          <Route
                            path="/merchant"
                            element={
                              <ProtectedRoute requiredPermission="merchant">
                                <Merchant />
                              </ProtectedRoute>
                            }
                            exact
                          />

                          <Route
                            path="/excel-data"
                            element={
                              <ProtectedRoute requiredPermission="excel-data">
                                <ExcelUploader />
                              </ProtectedRoute>
                            }
                            exact
                          />
                          <Route
                            path="/excel-data-table"
                            element={
                              <ProtectedRoute requiredPermission="excel-data-table">
                                <ExcelViewer />
                              </ProtectedRoute>
                            }
                            exact
                          />
                          <Route
                            path="/maintenance"
                            element={
                              <ProtectedRoute requiredPermission="maintenance">
                                <Maintenance />
                              </ProtectedRoute>
                            }
                            exact
                          />

                          <Route
                            path="/subscriptions/:merchantName/:merchantId"
                            element={
                              <ProtectedRoute requiredPermission="subscriptions-page">

                            <MerchantSubscription />
                            </ProtectedRoute>
                          }
                            exact
                          />

                          <Route
                            path="/subscriptionDetail/:merchantId/:subId"
                            element={
                              <ProtectedRoute requiredPermission="subscription-details">

                            <SubscriptionDetails />
                            </ProtectedRoute>
                          }
                            exact
                          />
                          <Route
                            path="/owner"
                            element={
                              <ProtectedRoute requiredPermission="owner">
                                <Owner />
                              </ProtectedRoute>
                            }
                            exact
                          />

                          <Route
                            path="/payout"
                            element={
                              <ProtectedRoute requiredPermission="payout">
                                <Payout />
                              </ProtectedRoute>
                            }
                            exact
                          />

                          <Route
                            path="/charges/:merchantId"
                            element={<Charges />}
                            exact
                          />
                          <Route
                            path="/email"
                            element={
                              <ProtectedRoute requiredPermission="email">
                                <Email />
                              </ProtectedRoute>
                            }
                            exact
                          />
                          <Route
                            path="/products"
                            element={
                              <ProtectedRoute requiredPermission="products">
                                <Products />
                              </ProtectedRoute>
                            }
                            exact
                          ></Route>
                          <Route
                            path="/create-product"
                            element={
                              <ProtectedRoute requiredPermission="create-product">

                            <CreateProduct />
                            </ProtectedRoute>
                          }
                            exact
                          ></Route>
                        </Route>
                      </Routes>
                    </Col>
                  </Row>
                </Container>
              </div>
            </PermissionsProvider>
          </AuthProvider>
          </ImageProvider>
        </Router>
      </div>
    </>
  );
}

export default App;
