import React, { useEffect, useState } from 'react'
import ProductsHeader from '../components/products/ProductsHeader'
import ProductsTable from '../components/tables/ProductsTable/ProductsTable'
import {getProducts} from "../common/services/products";
import ProductsPagination from '../components/tables/ProductsTable/ProductsPagination';

const Products = () => {
  const [tableData, setTableData] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  useEffect(() => {
    getProduct()
  }, []);
  const getProduct = async () => {
    let data = await getProducts();
    if (data) {
      setTableData(data);
      setHasMore(data[data?.length - 1]["has_more"])
    }
  }
  return (
    <main>
      <ProductsHeader />
      <ProductsTable data={tableData} setData={setTableData} />
      {tableData && tableData[0] &&
      <>
        <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
          <ProductsPagination hasMore={hasMore} data={tableData} setData={setTableData} URL="/products" />
        </div>
      </>
      }
    </main>
  )
}

export default Products