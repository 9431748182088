import React, { useState } from 'react';
import { storage, } from '../firebase';
import {  ref, uploadBytes } from 'firebase/storage';
import { Container, Form, Button, Alert } from 'react-bootstrap';


const ExcelUploader = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    // Reset messages
    setError(null);
    setSuccess(null);
  };

  const handleUpload = async () => {
    if (!file) {
      setError('Please select a file first!');
      return;
    }

    setUploading(true);
    setError(null);
    setSuccess(null);

    try {
      // Create a storage reference with a fixed name to replace previous file
      const storageRef = ref(storage, 'uploaded_excel/data.xlsx');

      // Convert file to ArrayBuffer for Firebase upload
      const arrayBuffer = await file.arrayBuffer();

      // Upload the file
      await uploadBytes(storageRef, arrayBuffer);

      setSuccess('File uploaded successfully!');
    } catch (error) {
      console.error('Upload error:', error);
      setError('File upload failed');
    } finally {
      setUploading(false);
    }
  };

  return (
    <Container className="mt-5">
      <h2 className="mb-4">Excel File Uploader</h2>
      
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      
      <Form>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Choose Excel File</Form.Label>
          <Form.Control 
            type="file" 
            accept=".xlsx, .xls" 
            onChange={handleFileChange}
          />
        </Form.Group>
        
        <Button 
          variant="success" 
          onClick={handleUpload} 
          disabled={uploading}
        >
          {uploading ? 'Uploading...' : 'Upload Excel'}
        </Button>
      </Form>
    </Container>
  );
};

export default ExcelUploader;