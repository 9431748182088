import React, { useState, useEffect } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase'; // Adjust the path to your firebase.js file
import * as XLSX from 'xlsx';
import { Container, Button, Spinner, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const ExcelViewer = () => {
  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFileFromFirebase = async () => {
      try {
        // Reference the file in Firebase Storage
        const fileRef = ref(storage, "uploaded_excel/data.xlsx"); // Adjust the path as needed

        const fileUrl = await getDownloadURL(fileRef);

        // Fetch the file
        const response = await fetch(fileUrl);
        const arrayBuffer = await response.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: "array" });
        
        const sheetName = workbook.SheetNames[0]; // Assuming the first sheet
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Extract headers and rows
        setTableHeaders(jsonData[0]); // First row as headers
        setTableData(jsonData.slice(1)); // Remaining rows as data
        setLoading(false);
      } catch (error) {
        console.error("Error fetching or parsing the Excel file:", error);
        setLoading(false);
      }
    };

    fetchFileFromFirebase();
  }, []);

  const handleExport = () => {
    // Combine headers and data for export
    const dataToExport = [tableHeaders, ...tableData];

    // Create a new workbook and worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate and download the Excel file
    XLSX.writeFile(workbook, "exported_data.xlsx");
  };

  return (
    <Container className="my-4">
      <div className="mb-3">
        <Button 
          variant="success" 
          onClick={handleExport}
          disabled={tableData.length === 0}
        >
          Export to Excel
        </Button>
      </div>

      {loading ? (
        <div className="text-center my-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : tableData.length > 0 ? (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              {tableHeaders.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="text-center text-muted my-5">
          No data to display.
        </div>
      )}
    </Container>
  );
};

export default ExcelViewer;