import React, { useState, useEffect } from "react";
import { Badge } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import Axios from "../components/axios/AxiosOnboarding";

const Maintenance = () => {
  const [isProductCreateActive, setIsProductCreateActive] = useState(false);
  const [productMessage, setProductMessage] = useState("");
  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
      // If no stored value, fetch from backend
      const fetchMaintenanceStatus = async () => {
        try {
          const res = await Axios.get("/admin/maintenance/get", {
            headers: {
              Authorization: `Bearer ${apiKey}`,
            },
          });
          console.log("initial",res)

          setIsProductCreateActive(res.data.is_active);
          setProductMessage(res.data.message);

        } catch (error) {
          toast.error(error.response?.data?.message || error.message);
        }
      };

      fetchMaintenanceStatus();
    }
 , [apiKey]);

  const handleProductCreate = async () => {
    const newIsActive = !isProductCreateActive;
    const message = newIsActive
      ? "Maintenance mode is active for product_create"
      : "Maintenance mode is not active for product_create";

    setIsProductCreateActive(newIsActive);
    setProductMessage(message);


    try {
     const res = await Axios.post(
        `/admin/maintenance/update`,
        {
          is_active: newIsActive,
          message: message,
          mode: "product_create",
        },
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      console.log(res)
      toast.success("Product Create maintenance mode updated successfully");
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };


  return (
    <>
      <h3 className="p-3" style={{ fontWeight: "bold" }}>
        Maintenance
      </h3>

      <div
        className="d-flex flex-column justify-content-center align-items-center gap-5"
        style={{ minHeight: "100vh" }}
      >
        {/* Product Create Functionality */}
        <div className="d-flex align-items-center gap-4">
          <Badge
            bg={isProductCreateActive ? "success" : "secondary"}
            style={{ width: "20vw", padding: "0.6rem", fontSize: "15px" }}
          >
            Product Create functionality
          </Badge>
          <Form>
            <Form.Check
              type="switch"
              id="product-create-switch"
              label={isProductCreateActive ? "On" : "Off"}
              checked={isProductCreateActive}
              onChange={handleProductCreate}
            />
          </Form>
        </div>

        
      </div>
    </>
  );
};

export default Maintenance;
